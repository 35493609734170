// extracted by mini-css-extract-plugin
export var BtnOrange = "thankYou-module--BtnOrange--6f51b";
export var Container = "thankYou-module--Container--96533";
export var FlexWrap = "thankYou-module--FlexWrap--18e1a";
export var H1 = "thankYou-module--H1--17309";
export var H2 = "thankYou-module--H2--7cbe7";
export var H3 = "thankYou-module--H3--52636";
export var H4 = "thankYou-module--H4--7f989";
export var H5 = "thankYou-module--H5--351de";
export var H6 = "thankYou-module--H6--c0934";
export var ThankYou = "thankYou-module--ThankYou--7f852";
export var blockImg = "thankYou-module--block-img--19d07";
export var contentPage = "thankYou-module--contentPage--d3356";
export var sliderTray = "thankYou-module--sliderTray--ac706";