import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import * as s from './thankYou.module.scss'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
const ThankYouPage = () => (
  <div className={s.ThankYou}>
    <h1>Thank You!</h1>
    <p>We will get back to you soon.</p>
      <p>
        <PrismicLink href="/contact">
          <button type="button" className={s.BtnOrange}>Return to Contact</button>
        </PrismicLink>
      </p>
  </div>
)

export default withPrismicUnpublishedPreview(ThankYouPage)
